import "./dashboard.scss";
import dashusericon from "../../assets/images/dash-user.svg";
import dasprovidericon from "../../assets/images/dash-home.svg";
import daslocationicon from "../../assets/images/dash-location.svg";
import dasitemnoicon from "../../assets/images/dash-no-items.svg";
import dasinvetvalueicon from "../../assets/images/dash-inventory-value.svg";
import dasinvetexpvalueicon from "../../assets/images/dash-expired-inventory-value.svg";
import areachartimage from "../../assets/images/area-chart-img.svg";
import alertexpitem from "../../assets/images/alert-expired-inventory-icon.svg";
import alertrecalleditem from "../../assets/images/alert-recalled-inventory-icon.svg";
import alertexpiringitem from "../../assets/images/alert-expiring-inventory-icon.svg";
import { ReactSVG } from "react-svg";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import useDashboard from "../../Hooks/useDashboard";
import Loading from "../../components/LoadingPage/Loading";
import { Button, Form, FormText } from "react-bootstrap";
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { Bar, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import CustomModal from "../../components/customModal/CustomModal";
import useAuth from "../../Hooks/useAuth";
import { useAppSelector } from "../../redux/hooks";
import { selectCurrentUserId } from "../../redux/auth/authSlice";

// Register the necessary elements and plugins
ChartJS.register(
  BarElement,
  CategoryScale,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  ChartDataLabels,
);

const allLocOption = [
  { value: "default", label: "All Locations" },
  {
    value: "1234 Maple Street Springfield, Anytown, USA",
    label: "1234 Maple Street Springfield, Anytown, USA",
  },
  {
    value: "5678 Oak Avenue Riverside, Smalltown, USA",
    label: "5678 Oak Avenue Riverside, Smalltown, USA",
  },
  {
    value: "910 Elm Street Lakeside, Anytown, USA",
    label: "910 Elm Street Lakeside, Anytown, USA",
  },
  {
    value: "246 Pine Drive Sunnyvale, Suburbia, USA",
    label: "246 Pine Drive Sunnyvale, Suburbia, USA",
  },
  {
    value: "789 Washington Boulevard Hillcrest, Middle America, USA",
    label: "789 Washington Boulevard Hillcrest, Middle America, USA",
  },
];
const thisYearOpt = [
  { value: "default", label: "This Year" },
  { value: "january", label: "Jan" },
  { value: "february", label: "Feb" },
  { value: "march", label: "Mar" },
  { value: "april", label: "Apr" },
  { value: "may", label: "May" },
  { value: "june", label: "Jun" },
  { value: "july", label: "Jul" },
  { value: "august", label: "Aug" },
  { value: "september", label: "Sep" },
  { value: "october", label: "Oct" },
  { value: "november", label: "Nov" },
  { value: "december", label: "Dec" },
];

// Predefined color palette
const colorPalette = [
  "#FF6384", // Red
  "#36A2EB", // Blue
  "#FFCE56", // Yellow
  "#4BC0C0", // Teal
  "#9966FF", // Purple
  "#FF9F40", // Orange
];
const DashBoard = () => {
  const {
    getUserCountAPI,
    getUserCountLoading,
    getInventoryValue,
    getDashboardAlerts,
    getTopTransactionItems,
    getInventoryValuesByType,
    getInventoryValueByLocation,
  } = useDashboard();
  const currentUserId = useAppSelector(selectCurrentUserId);
  const [selectedAllLoc, setSelectedAllLoc] = useState(allLocOption[0]);
  const [selectedThisYear, setSelectedThisYear] = useState(thisYearOpt[0]);
  const [isNewUserLogin , setIsNewUserLogin] = useState<string |null>(null);
  const { changePasswordApi, changePasswordLoading, logout } = useAuth();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState(false);
  const [usersData, setUsersData] = useState({
    items: 0,
    locations: 0,
    providers: 0,
    users: 0,
  });
  const [inventoryValues, setInventoryValues] = useState({
    inventoryValue: 0,
    expiredInventoryValue: 0,
  });

  const [dashboardAlerts, setDashboardAlerts] = useState({
    noOfExpiredItems: 0,
    noOfItemsExpireInNext30Days: 0,
    noOfRecalledItems: 0,
  });

  const [topTransactionItems, setTopTransactionItems] = useState([
    { itemName: "", type: "", transactionCount: 0 },
  ]);

  const [donutChartData, setDonutChartData] = useState([
    { inventoryType: "", inventoryPercentage: 0, inventoryTotal: 0 },
  ]);
  const [barChartData, setBarChartData] = useState([
    { inventoryValue: 0, locationCode: 0, locationName: "" },
  ]);

  useEffect(() => {
    const isNewUser = localStorage.getItem("changePasswordOnLogin");
    setIsNewUserLogin(isNewUser);
    (async () => {
      // Call all APIs concurrently using Promise.all
      const [
        userCountResponse,
        inventoryValueResponse,
        dashboardAlertsResponse,
        topTransactionItemsResponse,
        inventoryValueByTypeResponse,
        inventoryValueByLocationResponse,
      ] = await Promise.all([
        getUserCountAPI(),
        getInventoryValue(),
        getDashboardAlerts(),
        getTopTransactionItems(),
        getInventoryValuesByType(),
        getInventoryValueByLocation(),
      ]);
      // Set state with the responses
      setUsersData(userCountResponse[0]);
      setInventoryValues(inventoryValueResponse[0]);
      setDashboardAlerts(dashboardAlertsResponse[0]);
      setTopTransactionItems(topTransactionItemsResponse);
      setDonutChartData(inventoryValueByTypeResponse);
      setBarChartData(inventoryValueByLocationResponse);
    })();
  }, []);

   const { handleChange, values, handleSubmit, touched, errors, resetForm } =
    useFormik({
      initialValues: {
        id: currentUserId,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      validationSchema: Yup.object({
        currentPassword: Yup.string().required("Old Password is Required"),
        newPassword: Yup.string().required("New Password is Required"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("newPassword")], "Passwords must match")
          .required("Confirm Password is Required"),
      }),

      onSubmit: async (values: any, { setSubmitting }: FormikHelpers<any>) => {
        try {
          changePasswordApi(values).then((res:any) => {
            if (res) {
              logout();
            }
          });
        } catch (error) {}
        setSubmitting(false);
      },
    });

  const donutChartDataSet = {
    labels: donutChartData.map((data) => data.inventoryType),
    datasets: [
      {
        data: donutChartData.map((data) => data.inventoryPercentage),
        borderWidth: 1,
        backgroundColor: donutChartData.map(
          (_, index) => colorPalette[index % colorPalette.length],
        ),
      },
    ],
  };

  const donutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "bottom" as const,
        labels: {
          padding: 20, // Adjust the padding to minimize gap
          usePointStyle: true, // Use point style for legend
          pointStyle: "circle", // Set the point style to circle
        },
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem: { label: any; raw: any }) => {
            return `${tooltipItem.label}: ${tooltipItem.raw}%`;
          },
        },
      },

      datalabels: {
        color: "#fff",
        formatter: (value: number) => {
          const percentage = value + "%";
          return percentage;
        },
      },
    },
  };

  const barChartDataSet = {
    labels: barChartData.map((data) => data.locationName),
    datasets: [
      {
        label: "",
        data: barChartData.map((data) => data.inventoryValue),
        backgroundColor: "rgba(54, 162, 235, 0.7)",
        borderWidth: 1,
        borderRadius: 10,
        barPercentage: 0.3,
      },
    ],
  };

  const hasNegativeValues = barChartDataSet.datasets.some((dataset) =>
    dataset.data.some((value) => value < 0),
  );

  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: { raw: any }) {
            return `$${context.raw}`; // Format y-axis values as dollars
          },
        },
      },
      datalabels: {
        display: false, // Disable datalabels for this bar chart
      },
    },
    scales: {
      y: {
        grid: {
          drawBorder: false, // Hide the y-axis border
          drawOnChartArea: true, // Ensure the grid lines are drawn on the chart area
          drawTicks: false, // Hide the y-axis ticks
          color: (context: { tick: { value: number } }) => {
            if (context.tick.value === 0 && hasNegativeValues) {
              return "#9b9a9a"; // Hide the line, we will draw a custom one
            }
            return "rgba(0, 0, 0, 0)"; // No color for other grid lines
          },
        },
        border: {
          dash: (context: { tick: { value: number } }) => {
            if (context.tick.value === 0 && hasNegativeValues) {
              return [2, 4]; // Color for the zero line
            }
          },
        },
        ticks: {
          callback: function (value: any) {
            return `$ ${value}`; // Format y-axis values as dollars
          },
        },
        beginAtZero: true,
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Remove horizontal grid lines
          drawOnChartArea: false,
        },
      },
    },
  };

  return (
    <>
      <div className="dashboard-header-box">
        <h2>Hi, Welcome Back!</h2>
      </div>
      {getUserCountLoading ? <Loading /> : null}
      <div className="dash-overview-wrapper">
        <div className="dash-overview-box">
          <div className="dash-overview-title">
            <ReactSVG className="svg-box" wrapper="span" src={dashusericon} />
            <span>No. of Users</span>
          </div>
          <div className="dash-overview-count">{usersData?.users ?? 0}</div>
        </div>
        <div className="dash-overview-box">
          <div className="dash-overview-title">
            <ReactSVG
              className="svg-box"
              wrapper="span"
              src={dasprovidericon}
            />
            <span>No. of Providers</span>
          </div>
          <div className="dash-overview-count">{usersData?.providers ?? 0}</div>
        </div>
        <div className="dash-overview-box">
          <div className="dash-overview-title">
            <ReactSVG
              className="svg-box"
              wrapper="span"
              src={daslocationicon}
            />
            <span>Active Locations</span>
          </div>
          <div className="dash-overview-count">{usersData?.locations ?? 0}</div>
        </div>
        <div className="dash-overview-box">
          <div className="dash-overview-title">
            <ReactSVG className="svg-box" wrapper="span" src={dasitemnoicon} />
            <span>No. of Items</span>
          </div>
          <div className="dash-overview-count">{usersData?.items ?? 0}</div>
        </div>
        <div className="dash-overview-box">
          <div className="dash-overview-title">
            <ReactSVG
              className="svg-box"
              wrapper="span"
              src={dasinvetvalueicon}
            />
            <span>Inventory Value</span>
          </div>
          <div className="dash-overview-count">
            ${inventoryValues.inventoryValue}
          </div>
        </div>
        <div className="dash-overview-box">
          <div className="dash-overview-title">
            <ReactSVG
              className="svg-box"
              wrapper="span"
              src={dasinvetexpvalueicon}
            />
            <span>Expired Inv. Value</span>
          </div>
          <div className="dash-overview-count">
            ${inventoryValues.expiredInventoryValue}
          </div>
        </div>
      </div>
      <div className="dashboard-grid-wrapper">
        {/* <div className="dashboard-grid span2">
          <div className="dashboard-grid-title-box">
            <h3>Inventory Value by Month</h3>
            <div className="select-opt-box">
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={selectedThisYear}
                options={thisYearOpt}
              />
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                defaultValue={selectedAllLoc}
                options={allLocOption}
              />
            </div>
          </div>
          <div className="chart-box">
            <ReactSVG className="svg-box" wrapper="span" src={areachartimage} />
          </div>
        </div> */}
        <div className="dashboard-grid span2">
          <div className="dashboard-grid-title-box">
            <h3>Inventory Value by Location</h3>
          </div>
          <div className="chart-box">
            <Bar
              data={barChartDataSet}
              options={barChartOptions}
              width={400}
              height={300}
            />
          </div>
        </div>
        <div className="dashboard-grid">
          <div className="dashboard-grid-title-box">
            <h3>Value By Inventory Type</h3>
          </div>
          <div className="chart-box">
            <Doughnut
              data={donutChartDataSet}
              options={donutChartOptions}
              width={250}
              height={250}
            />
            <div className="donutCount">
              <span className="total">Total</span>
              <br />
              <span className="totalValue">
                ${donutChartData[0]?.inventoryTotal.toLocaleString()}
              </span>
            </div>
          </div>
        </div>
        <div className="dashboard-grid span2">
          <div className="dashboard-grid-title-box">
            <h3>Top 10 Transaction Items</h3>
          </div>
          <div className="dash-table-wrapper">
            <Table bordered={false} hover={true}>
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Type</th>
                  <th>Transaction Count</th>
                </tr>
              </thead>
              <tbody>
                {topTransactionItems.map((item, index) => (
                  <tr key={index}>
                    <td>{item.itemName}</td>
                    <td>{item.type}</td>
                    <td>{item.transactionCount.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="dashboard-grid">
          <div className="dashboard-grid-title-box">
            <h3>Alerts</h3>
          </div>
          <div className="dash-alert-content">
            <ul>
              <li>
                <div className="dash-alert-title">
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={alertexpitem}
                  />{" "}
                  No. of Expired Items
                </div>
                <div className="dash-alert-value">
                  {dashboardAlerts.noOfExpiredItems}
                </div>
              </li>
              <li>
                <div className="dash-alert-title">
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={alertrecalleditem}
                  />{" "}
                  No. of Recalled Items
                </div>
                <div className="dash-alert-value">
                  {dashboardAlerts.noOfRecalledItems}
                </div>
              </li>
              <li>
                <div className="dash-alert-title">
                  <ReactSVG
                    className="svg-box"
                    wrapper="span"
                    src={alertexpiringitem}
                  />{" "}
                  Items Expiring in Next 30 Days
                </div>
                <div className="dash-alert-value">
                  {dashboardAlerts.noOfItemsExpireInNext30Days}
                </div>
              </li>
            </ul>
          </div>
        </div>
        {isNewUserLogin === "true" && (
        <CustomModal
          id="change-password-modal"
          title="Change Password"
          content={
            <>
              <div className="cost-calc-box">
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-4 password-field-box">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                      type={showCurrentPassword ? "text" : "password"}
                      placeholder="Enter Old Password"
                      name="currentPassword"
                      id="currentPassword"
                      onChange={handleChange}
                      value={values.currentPassword}
                      isInvalid={
                        touched.currentPassword && !!errors.currentPassword
                      }
                    />
                    {touched.currentPassword && errors.currentPassword ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.currentPassword}
                      </Form.Control.Feedback>
                    ) : null}
                    {showCurrentPassword ? (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => {
                          setShowCurrentPassword(!showCurrentPassword);
                        }}
                      />
                    ) : (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => {
                          setShowCurrentPassword(!showCurrentPassword);
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4 password-field-box">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter New Password"
                      name="newPassword"
                      id="newPassword"
                      onChange={handleChange}
                      value={values.newPassword}
                      isInvalid={touched.newPassword && !!errors.newPassword}
                    />

                    {touched.newPassword && errors.newPassword ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.newPassword}
                      </Form.Control.Feedback>
                    ) : null}

                    {showPassword ? (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    ) : (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4 password-field-box">
                    <Form.Label>Re-Enter New Password</Form.Label>
                    <Form.Control
                      type={showVerifyPassword ? "text" : "password"}
                      placeholder="Re-Enter New Password"
                      name="confirmPassword"
                      id="confirmPassword"
                      onChange={handleChange}
                      value={values.confirmPassword}
                      isInvalid={
                        touched.confirmPassword && !!errors.confirmPassword
                      }
                    />
                    {touched.confirmPassword && errors.confirmPassword ? (
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    ) : null}
                    {showVerifyPassword ? (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => {
                          setShowVerifyPassword(!showVerifyPassword);
                        }}
                      />
                    ) : (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => {
                          setShowVerifyPassword(!showVerifyPassword);
                        }}
                      />
                    )}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <FormText>
                      Please note that you will be logged out of the system on
                      changing the password
                    </FormText>
                  </Form.Group>
                  <div className="full-width text-end">
                    {/* <Button
                      style={{ marginRight: "10px" }}
                      variant="outline-primary"
                      onClick={toggleModal}
                    >
                      Cancel
                    </Button> */}
                    <Button type="submit">Submit</Button>
                  </div>
                </Form>
              </div>
            </>
          }
          // onClose={toggleModal}
        />
      )}
      </div>
    </>
  );
};

export default DashBoard;
