import ReportHeader from "../../../components/reportHeader/ReportHeader";
import exporticon from "../../../assets/images/export-icon.svg";
import { ReactSVG } from "react-svg";
import { useState } from "react";
import DatePicker from "react-date-picker";
import { MultiSelect } from "react-multi-select-component";
import TelerikReporting from "../../../components/TelerikReporting/TelerikReporting";
import moment from "moment";

const locationOptions = [
  {
    label: "1234 Maple Street Springfield, Anytown, USA",
    value: "1234 Maple Street Springfield, Anytown, USA",
  },
  {
    label: "5678 Oak Avenue Riverside, Smalltown, USA",
    value: "5678 Oak Avenue Riverside, Smalltown, USA",
  },
  {
    label: "910 Elm Street Lakeside, Anytown, USA",
    value: "910 Elm Street Lakeside, Anytown, USA",
  },
  {
    label: "246 Pine Drive Sunnyvale, Suburbia, USA",
    value: "246 Pine Drive Sunnyvale, Suburbia, USA",
  },
  {
    label: "789 Washington Boulevard Hillcrest, Middle America, USA",
    value: "789 Washington Boulevard Hillcrest, Middle America, USA",
  },
  {
    label: "910 Elm Street Lakeside, Anytown, USA",
    value: "910 Elm Street Lakeside, Anytown, USA",
  },
  {
    label: "246 Pine Drive Sunnyvale, Suburbia, USA",
    value: "246 Pine Drive Sunnyvale, Suburbia, USA",
  },
];
const stockOptions = [
  { label: "Private", value: "private" },
  { label: "Federal", value: "federal" },
  { label: "Petients", value: "petients" },
];
const typeOptions = [
  { label: "Pediatric Vaccine (PDV)", value: "Pediatric Vaccine" },
  { label: "Vaccine (VAC)", value: "Vaccine" },
  { label: "Procedure (PROC)", value: "Procedure" },
  { label: "Injection (INJEC)", value: "Injection" },
  { label: "IT Equipment 1 (ITEQUIP)", value: "IT Equipment 1 (ITEQUIP)" },
  { label: "Covid 19 Test (COVID 19)", value: "Covid 19 Test (COVID 19)" },
];

const overrideStrings = {
  search: "Search",
  selectAll: "All",
  selectSomeItems: "All",
};
const InventoryStatusReport = () => {
  const [selectedStock, setSelectedStock] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedLoc, setSelectedLoc] = useState([]);
  const [date, setDate] = useState("");
  const [lot, setLot] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [isIncludeZero, setIsIncludeZero] = useState(true);
  const [reportKey, setReportKey] = useState(0);

  const onSelectDate = (date: any) => {
    setDate(moment(date).utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"));
    setReportKey((prevKey) => prevKey + 1);
  };
  const onLotChange = (e: any) => {
    setLot(e.target.value);
    setReportKey((prevKey) => prevKey + 1);
  };
  const cleearAll = () => {
    setDate("");
    setLot("");
    setIsIncludeZero(true);
    setIsActive(true);
  };
  return (
    <>
    {/* <div className="report-content-wrapper"> */}
      {/* <ReportHeader
        reportTitle="Inventory Status Report"
        reportDate="04 April 2024"
      /> */}
      <div className="report-filter-wrapper" style={{ marginBottom: "20px" }}>
        <div className="report-filter-item-wrapper">
          <div className="filter-item-box">
            <label className="form-label">Date</label>
            <DatePicker
              format="MM/dd/yyyy"
              openCalendarOnFocus={false}
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              className="form-control"
              value={date}
              onChange={(date: any) => {
                onSelectDate(date);
              }}
              clearIcon={null}
              calendarIcon={<i className="bi bi-calendar"></i>}
            />
          </div>
          <div className="filter-item-box location-filter-item">
            <label className="form-label">Location</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={locationOptions}
              value={selectedLoc}
              onChange={setSelectedLoc}
              labelledBy="Select"
            />
          </div>
          <div className="filter-item-box type-filter-item">
            <label className="form-label">Type</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={typeOptions}
              value={selectedStock}
              onChange={setSelectedStock}
              labelledBy="Select"
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Stock</label>
            <MultiSelect
              overrideStrings={overrideStrings}
              options={stockOptions}
              value={selectedType}
              onChange={setSelectedType}
              labelledBy="Select"
              disableSearch={true}
            />
          </div>
          <div className="filter-item-box">
            <label className="form-label">Lot</label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter Lot"
              value={lot}
              onChange={onLotChange}
            />
          </div>
          <div className="filter-item-box filter-checkbox-items">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isActive}
                onChange={(e) => {
                  setIsActive(e.target.checked);
                  setReportKey((prevKey) => prevKey + 1);
                }}
              />
              <label className="form-check-label">Active</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isIncludeZero}
                onChange={(e) => {
                  setIsIncludeZero(e.target.checked);
                  setReportKey((prevKey) => prevKey + 1);
                }}
              />
              <label className="form-check-label">Include 0 Quantity</label>
            </div>
          </div>
          <div className="filter-item-box clear-all-box">
            <button
              type="button"
              className="clear-filter-btn"
              onClick={cleearAll}
            >
              Clear All
            </button>
          </div>
        </div>
        {/* <div className="report-export-box">
          <button type="button" className="report-export-btn">
            <ReactSVG className="svg-box" wrapper="span" src={exporticon} />{" "}
            Export
          </button>
        </div> */}
      </div>
      <TelerikReporting
        key={reportKey}
        params={{
          reportDate: date,
          locationId: "",
          drxTypeId: "",
          stockId: "",
          lotNo: "",
          isActive: isActive,
          isZeroQty: isIncludeZero,
          isExport: "true",
        }}
        reportName="InventoryStatusReport.trdp"
        reportEndPoint="reports/inventorystatus/get"
      />
      </>
    //  </div> 
  );
};

export default InventoryStatusReport;
