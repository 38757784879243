import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import { RootState } from "./../store";
export interface AuthData {
  jwtToken: string | null;
  refreshToken: string | null;
  email: string | null;
  firstName: string | null;
  lastName: string | null;
  username: string | null;
  id: string | null;
  roleName: string | null;
  roleId: string | null;
  exp: number | null;
  clientId: string | null;
  menuItems: any;
  permissions: any;
  changePasswordOnLogin: string |null;
}

export interface AuthState extends AuthData {
  isLoggedIn: boolean;
}

const initialState: AuthState = {
  jwtToken: localStorage.getItem("jwtToken")
    ? localStorage.getItem("jwtToken")
    : null,
  refreshToken: localStorage.getItem("refreshToken")
    ? localStorage.getItem("refreshToken")
    : null,
  exp: +localStorage.getItem("exp")! ? +localStorage.getItem("exp")! : null,
  isLoggedIn: !!localStorage.getItem("jwtToken"),
  id: localStorage.getItem("id") || null,
  roleName: localStorage.getItem("roleName") || null,
  roleId: localStorage.getItem("roleId") || null,
  email: localStorage.getItem("email") || null,
  firstName: localStorage.getItem("firstName") || null,
  lastName: localStorage.getItem("lastName") || null,
  username: localStorage.getItem("username") || null,
  clientId: localStorage.getItem("clientId") || null,
  menuItems: JSON.parse(localStorage.getItem("menuItems") ?? "[]"),
  permissions: JSON.parse(localStorage.getItem("permissions") ?? "[]"),
  changePasswordOnLogin: localStorage.getItem("changePasswordOnLogin") || null
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action: PayloadAction<any>) => {
      const {
        jwtToken,
        refreshToken,
        email,
        firstName,
        lastName,
        id,
        role,
        username,
        changePasswordOnLogin
      } = action.payload;

      if (jwtToken) {
        const { exp } = jwtDecode(jwtToken);
        state.exp = exp || null;
      }
      state.jwtToken = jwtToken;
      state.refreshToken = refreshToken;
      state.email = email;
      state.firstName = firstName;
      state.lastName = lastName;
      state.id = id;
      state.roleName = role?.roleName ?? "";
      state.roleId = role?.id ?? "";
      state.username = username;
      state.isLoggedIn = true;
      state.changePasswordOnLogin = changePasswordOnLogin;
    },
    resetAuthState: (state) => {
      state.jwtToken = null;
      state.refreshToken = null;
      state.exp = null;
      state.isLoggedIn = false;
      state.id = null;
      state.id = null;
      state.roleName = null;
      state.email = null;
      state.firstName = null;
      state.lastName = null;
      state.username = null;
      state.changePasswordOnLogin = null;
    },
    setUserId: (state, action) => {
      const { userId } = action.payload;
      state.id = userId;
    },
    setMenuItems: (state, action) => {
      state.menuItems = action.payload;
      localStorage.setItem("menuItems", JSON.stringify(action.payload));
    },
    setPermissions: (state, action) => {
      state.permissions = action.payload;
      localStorage.setItem("permissions", JSON.stringify(action.payload));
    },
    setClientId: (state, action) => {
      const { clientId } = action.payload;
      state.clientId = clientId;
    },
    resetUserId: (state) => {
      state.id = null;
    },
  },
});

export const {
  setCredentials,
  resetAuthState,
  setUserId,
  setClientId,
  resetUserId,
  setMenuItems,
  setPermissions,
} = authSlice.actions;

export default authSlice.reducer;

// Selectors
export const selectCurrentLoginStatus = (state: RootState) =>
  state.auth.isLoggedIn;
export const selectCurrentAccessToken = (state: RootState) =>
  state.auth.jwtToken;
export const selectCurrentRefreshToken = (state: RootState) =>
  state.auth.refreshToken;
export const selectCurrentAuthState = (state: RootState) => state.auth;
export const selectCurrentUserId = (state: RootState) => state.auth.id;
export const selectedRoleName = (state: RootState) => state.auth.roleName;
export const selectedRoleId = (state: RootState) => state.auth.roleId;
export const tokenExpTime = (state: RootState) => state.auth.exp;
export const clientId = (state: RootState) => state.auth.clientId;
export const getMenuItems = (state: RootState) => state.auth.menuItems;
export const getPermissionsList = (state: RootState) => state.auth.permissions;
