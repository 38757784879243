import "./settingprofile.scss";
import SettingProfileHeader from "../../../components/settingprofileheader/SettingProfileHeader";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import useSettings from "../../../Hooks/useSettings";
import { Setting } from "../../../redux/settings/settingsSlice";
import Loading from "../../../components/LoadingPage/Loading";
import { DeleteConfModel } from "../../../components/DeleteConfModel/deleteConfModel";
import { selectCurrentUserId } from "../../../redux/auth/authSlice";
import { useAppSelector } from "../../../redux/hooks";
import { toggleClass } from "../../../utils/commonUtils";
const SettingProfile = () => {
  const { id } = useParams();
  const [settingDetails, setSettingDetails] = useState<Setting>();
  const { getSettingsByIdAPI, getSettingsByIdLoading } = useSettings();
  const { deleteSettingAPI, deleteSettingLoading } = useSettings();

  const currentUserId = useAppSelector(selectCurrentUserId);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    getSettingsByIdAPI(id ?? "").then((res: Setting) => {
      if (res) {
        setSettingDetails(res);
      }
    });
  }, []);
  const deleteSetting = async () => {
    await deleteSettingAPI({
      id: settingDetails?.id,
      updatedBy: currentUserId ?? "",
    });
    toggleModal();
    navigate("/settings");
  };
  const toggleModal = () => {
    toggleClass();
    setShowModal(!showModal);
  };
  return (
    <div className="profile-page-wrapper">
      {getSettingsByIdLoading || deleteSettingLoading ? <Loading /> : null}
      <SettingProfileHeader
        title={settingDetails?.settingName ?? "-"}
        id={id}
        toggleModal={toggleModal}
      />
      <div className="profile-detail-box-wrapper">
        <div className="profile-detail-box">
          <div className="profile-info-box">
            <label>Setting Name</label>
            <p>{settingDetails?.settingName ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Setting Value</label>
            <p>{settingDetails?.settingValue ?? "-"}</p>
          </div>
          <div className="profile-info-box">
            <label>Category</label>
            <p>{settingDetails?.category ?? "-"}</p>
          </div>
          <div className="profile-info-box full-width">
            <label>Notes</label>
            <textarea
              value={settingDetails?.notes || ""}
              disabled={true}
              rows={3}
              style={{
                width: "100%",
                resize: "vertical",
                backgroundColor: "white",
                fontSize: "14px",
                color: "black",
              }}
            />
          </div>
        </div>
      </div>
      <DeleteConfModel
        showModal={showModal}
        toggleModal={toggleModal}
        deleteAction={deleteSetting}
        recordName={settingDetails?.settingName || ""}
        modelName={`setting`}
      />
    </div>
  );
};

export default SettingProfile;
